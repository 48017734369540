(function($, window, document) {
	$(function() {
		var nav_container = $(".mobile-nav");
		var stagger = $('.mobile-stagger');

		$(".js-mobile-nav").click(function() {
			if (nav_container.hasClass("nav--visible")) {
				nav_container.removeClass("nav--visible");
				stagger.css({opacity: 0})
				 $('body').css({position: "fixed"})

			} else {
				nav_container.addClass("nav--visible");
				$('body').css({position: "relative"})
				setTimeout(function() {
					TweenMax.staggerFromTo(
						".mobile-stagger",
						0.6,
						{
							left: -100,
							opacity: 0,
							ease: Power2.easeOut,
							force3D: true
						},
						{
							left: 0,
							opacity: 1,
							ease: Power2.easeOut,
							force3D: true
						},
						0.1
					);
				}, 400);
			}
		});

		$(".js-mobile-nav--close").click(function() {
			nav_container.removeClass("nav--visible");
			stagger.css({opacity: 0})
			$('body').css({position: "relative"})
		});
	});
})(window.jQuery, window, document);
