// (function($, window, document) {
// 	$(function() {

// 		window.SelectFx.onChange(function(){
// 			alert('d')
// 		})

// 		$(".language--select").on("change", function() {
// 			var url = $(this).val(); // get selected value

// 			if (url) {
// 				// require a URL
// 				window.location = url; // redirect
// 			}
// 			return false;
// 		});
// 	});
// })(window.jQuery, window, document);
